export default {
  created() {
    window.addEventListener("beforeunload", this.alertBlockBack);
    this.$once("hook:beforeDestroy", function() {
      window.removeEventListener("beforeunload", this.alertBlockBack);
    });
  },
  methods: {
    alertBlockBack() {
      alert("Your work will be lost.");
    }
  }
};
