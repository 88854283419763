<template>
  <div class="position-relative full-page">
    <stageHeader></stageHeader>
    <stageMenu></stageMenu>
  </div>
</template>
<script>
import stageHeader from "@/views/part/stageHeader";
// import mainMenu from "@/views/part/mainMenu";

export default {
  name: "mapArrive",
  components: {
    stageHeader
    // mainMenu
  }
};
</script>

<style scoped></style>
