<template>
  <div>
    <div class="full-video position-fixed d-flex align-items-center">
      <!-- <img v-if="playBtn" class="position-fixed play-btn" src="/img/icon-play.svg" alt="" /> -->
      <vimeo vimeo_key="S00" @ended="goMap" :autoplay="true" @play="play"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./part/vimeo";

export default {
  name: "openScene",
  components: {
    vimeo
  },
  data() {
    return {
      playBtn: true
    };
  },
  methods: {
    play() {
      this.playBtn = false;
    },
    goMap: function() {
      this.$router.replace("homeMap");
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
