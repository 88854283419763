<template>
  <div class="position-relative full-page">
    <logoutBtn position="upper-right"></logoutBtn>
    <div
      v-show="!localFlag('map_open')"
      class="full-video position-fixed open-mc"
      @click="play"
      :class="{ fadeOut: fadeOut }"
    >
      <img v-if="playBtn" class="position-fixed play-btn" src="/img/icon-play.svg" alt="" />
      <video class="cover-fit" ref="video" :src="getVideoPath('open')" playsinline></video>
    </div>
    <a v-if="flag('scoreResultEntered')" class="btn btn-primary btn-backToFinalPanel" href="/finalPanel">回結尾頁</a>
    <mainMenu :collapse="collapse"></mainMenu>
    <a class="return-position position-fixed d-flex justify-content-center align-items-center" @click="returnPosition">
      <img src="@/assets/images/icon-center.svg" alt="" />
    </a>
    <ul
      class="list-unstyled position-fixed location-list d-flex"
      :style="{ left: slideMove }"
      v-bind:class="{ 'hide-menu': !collapse }"
      v-touch:swipe.right="
        () => {
          setIndex(this.localIndex - 1);
        }
      "
      v-touch:swipe.left="
        () => {
          setIndex(this.localIndex + 1);
        }
      "
    >
      <li
        class="location-card position-relative d-flex align-items-center"
        v-for="location in locations_dis"
        :key="'locations_dis' + location.id"
      >
        <!-- <a
          v-show="flag(`stage_${location.stage}_clear`)"
          role="button"
          class="text-center position-absolute go-location cursor-pointer text-primary"
        > -->
        <a
          role="button"
          :href="location.map_href"
          target="_blank"
          class="text-center position-absolute go-location cursor-pointer text-primary"
        >
          <div style="font-size:0.5rem">查看地點</div>
          <svg class="btn-icon-28">
            <use xlink:href="#icon-map-location" class="fill-primary"></use>
          </svg>
          <div>{{ location.data }}m</div>
        </a>
        <div class="cha-circle circle mr-3 col-auto p-0">
          <img class="cover-fit" v-bind:src="showAvatar(location.picture)" />
        </div>
        <div>
          <div class="pb-2  d-flex flex-column align-items-center" v-html="location.name"></div>
          <a
            @click.stop.prevent="team_goto(location)"
            class="btn btn-primary btn-sm text-white"
            v-if="location.btnText != '' && isLeader"
          >
            {{ location.btnText }}
          </a>
          <a
            @click.stop.prevent="member_goto(location)"
            class="btn btn-primary btn-sm text-white"
            v-if="location.btnText != '' && !isLeader"
          >
            <template v-if="flag(location.unlockFlag) || /food/.test(location.link)">
              {{ location.btnText }}
            </template>
            <template v-else>
              等待隊長選擇
            </template>
          </a>
        </div>
      </li>
    </ul>
    <!--<div class="pic-switch position-absolute" @click="switchPicture" :class="{ active: showUserPicture }">-->
    <!--<div class="switch-circle"></div>-->
    <!--</div>-->
    <l-map
      ref="map"
      v-bind="map_config"
      style="height: 100vh"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="collapse = false"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="userCenter" :icon="user_location.icon"></l-marker>
      <!--      <l-marker :lat-lng="userCenter" :icon="user_avatar.icon"></l-marker>-->

      <div v-for="(stage, index) in stages" :key="'stages' + index">
        <l-polygon
          :lat-lngs="stage.bounds"
          :color="boundBorderColor(stage.status)"
          :fillColor="'#ccc'"
          :fillOpacity="0"
        >
        </l-polygon>
      </div>
      <!--      <Vue2LeafletMarkerCluster>-->
      <l-marker
        v-show="location.hideOnMap"
        v-for="(location, index) in locations_dis"
        :key="'locations_dis' + location.id"
        :lat-lng="location.local"
        :icon="useIcon(location)"
        @click="setIndex(index)"
      >
      </l-marker>

      <l-marker
        v-for="member in memberMarkers"
        :key="'memberMarkers' + member.user_id"
        :lat-lng="member.location"
        :icon="useIcon(member, { className: 'circle' })"
      >
        <l-popup>{{ member.user.nickname }} ({{ member.character.name }})</l-popup>
      </l-marker>

      <!--      </Vue2LeafletMarkerCluster>-->

      <l-image-overlay :url="'/img/' + mapMist.img" :bounds="mapMist.bounds"></l-image-overlay>

      <l-image-overlay
        v-if="!flag('stage_1_phase1Clear')"
        :url="'/img/' + mistImg[0].img"
        :bounds="mistImg[0].bounds"
      ></l-image-overlay>
      <l-image-overlay
        v-if="!flag('stage_2_phase1Clear') && !flag('stage_5_default')"
        :url="'/img/' + mistImg[1].img"
        :bounds="mistImg[1].bounds"
      ></l-image-overlay>
      <l-image-overlay
        v-if="!flag('stage_3_phase1Clear') && !flag('stage_6_default')"
        :url="'/img/' + mistImg[2].img"
        :bounds="mistImg[2].bounds"
      ></l-image-overlay>
      <l-image-overlay
        v-if="!flag('stage_4_phase1Clear')"
        :url="'/img/' + mistImg[3].img"
        :bounds="mistImg[3].bounds"
      ></l-image-overlay>
      <l-image-overlay
        v-if="!flag('stage_7_default')"
        :url="'/img/' + mistImg[4].img"
        :bounds="mistImg[4].bounds"
      ></l-image-overlay>
    </l-map>
  </div>
</template>

<script>
import mainMenu from "@/views/part/mainMenu";
import logoutBtn from "@/views/part/logoutBtn";
import Avatar from "@/Mixins/Avatar";
import { latLngBounds, latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  // LIcon,
  LPolygon,
  LImageOverlay
} from "vue2-leaflet";
// import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

import "leaflet/dist/leaflet.css";
import UserFromVuex from "../Mixins/UserFromVuex";
import { mapActions, mapGetters } from "vuex";
import { sendMessage } from "../utils/WebSocket";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import StageFromMixin from "../Mixins/StageFromMixin";
import ModalMixin from "../Mixins/ModalMixin";
import { getDeviceMotionPermission } from "../utils/Motion";
import DeviceApiMixin from "../Mixins/DeviceApiMixin";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import BlockBackButtonMixin from "@/Mixins/BlockBackButtonMixin";
import locations from "@/store/modules/data/locations";

export default {
  name: "stageMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    // LIcon,
    LPolygon,
    mainMenu,
    // LPolyline,
    LImageOverlay,
    // Vue2LeafletMarkerCluster,
    logoutBtn
  },
  mixins: [
    Avatar,
    UserFromVuex,
    WebSocketMixin,
    StageFromMixin,
    ModalMixin,
    DeviceApiMixin,
    TeamFromVuex,
    BlockBackButtonMixin
  ],
  computed: {
    ...mapGetters("Team", ["isLeader"]),
    ...mapGetters("Video", ["getVideoPath"]),
    ...mapGetters("Stage", ["locations_unlock"]),
    ...mapGetters("Game", ["game", "member_locations", "teams", "teams_user_selected", "users"]),
    ...mapGetters("Character", ["roles"]),
    ...mapGetters("User", ["intro_show_flag"]),
    locations_dis() {
      return this.locations_unlock(this.userCenter);
    },
    slideMove() {
      let slideWidth = this.windowWidth - 70;
      return 0 - slideWidth * this.localIndex - this.localIndex * 20 + 25 + "px";
    },
    showFinalStage() {
      if (this.flag("stage_7_default")) {
        return true;
      }
      for (let i = 1; i < 7; i++) {
        if (this.flag(`stage_${i}_clear`) == false) {
          return false;
        }
      }
      // this.setFlag({ key: "stage_7_default", status: true });
      return true;
    },
    showStagePosition(stage) {
      return stage;
    },
    memberMarkers() {
      let result = [];
      this.member_locations.forEach(team => {
        team.forEach(info => {
          if (info?.team_id && info?.user_id) {
            let charactor_id = this.teams_user_selected[info?.team_id][info?.user_id];
            info["character"] = this.roles[charactor_id];
            if (
              typeof info["location"]["latitude"] != "undefined" &&
              typeof info["location"]["longitude"] != "undefined"
            ) {
              info["location"] = [info["location"]["latitude"], info["location"]["longitude"]];
            }
            info["user"] = this.users[info?.user_id];
            info["icon"] = {
              className: "circle",
              iconUrl: this.users[info?.user_id].avatar,
              iconSize: [50, 50],
              iconAnchor: [25, 25]
            };
            if (this.user?.id && this.user.id != info?.user_id) {
              result.push(info);
            }
          }
        });
      });
      return result;
    }
  },
  props: {
    local: null,
    zoom: null
  },
  data() {
    return {
      playBtn: true,
      fadeOut: false,
      collapse: false,
      windowWidth: 0,
      showUserPicture: false,
      updateUserCenterTimeout: null,
      openStationStage: false,
      mapMist: {
        img: "mist_all.png",
        bounds: [
          [25.067076, 121.497201],
          [25.01465, 121.532401]
        ]
      },
      mistImg: [
        {
          img: "mist_s1.png", //南陽街
          bounds: [
            [25.04742, 121.5139],
            [25.04244, 121.520113]
          ]
        },
        {
          img: "mist_s2.png", //中正紀念堂
          bounds: [
            [25.042192, 121.508786],
            [25.02789, 121.528579]
          ]
        },
        {
          img: "mist_s3.png", //中山堂
          bounds: [
            [25.040089, 121.500561],
            [25.047428, 121.513455]
          ]
        },
        {
          img: "mist_s4.png", //228
          bounds: [
            [25.040096, 121.510005],
            [25.046186, 121.519913]
          ]
        },
        {
          img: "mist_s6.png", //台北火車站
          bounds: [
            [25.045697, 121.513489],
            [25.049689, 121.521703]
          ]
        }
      ],
      map_config: {
        // zoom: 16,
        minZoom: 15,
        center: [25.0436, 121.5139],
        bounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        maxBounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        options: {
          zoomSnap: 0.5
        }
      },
      userCenter: [25.0436, 121.5139],

      url: "https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles courtesy of <a href="http://www.openstreetmap.bzh/" target="_blank">Breton OpenStreetMap Team</a>',
      currentZoom: 16,
      currentCenter: latLng(25.042, 121.512),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      icon: icon({
        iconUrl: "/img/icon-pin.svg"
      }),
      user_location: {
        icon: icon({
          iconUrl: "/img/location.svg",
          iconSize: [100, 100],
          iconAnchor: [50, 50]
        })
      },
      user_avatar: {
        icon: icon({
          className: "circle",
          iconUrl: "/img/default-avatar.png",
          iconSize: [40, 40],
          iconAnchor: [20, 20]
        })
      },
      stageIndex: 0,
      localIndex: 0,
      ximenRoute: {
        start: {
          icon: {
            iconUrl: "/img/ximen-start.png",
            iconSize: [39, 71],
            iconAnchor: [32, 2]
          }
        },
        end: {
          icon: {
            iconUrl: "/img/ximen-end.png",
            iconSize: [26, 42],
            iconAnchor: [13, 42]
          }
        },
        polyline: {
          latlngs: [
            [25.04293, 121.507765],
            [25.043365, 121.507854],
            [25.043953, 121.505091],
            [25.045074, 121.5054],
            [25.045262, 121.504614]
          ],
          color: "#d9006c"
        }
      }
    };
  },
  activated() {
    if (this.local) {
      this.setCenter(this.local, this.zoom);
    } else {
      this.$refs.map.setZoom(16);
    }
  },
  mounted() {
    if (this.isLeader) {
      this.sendMemberLocation();
    }
    this.flagSyncTimer();
    this.$nextTick(() => {
      if (this.local) {
        this.setCenter(this.local, this.zoom);
      } else {
        this.$refs.map.setZoom(16);
      }
      this.$refs.video.addEventListener("timeupdate", () => {
        if (this.$refs.video?.currentTime >= 7.5) {
          this.fadeOut = true;
        }
      });
      this.$refs.video.addEventListener("ended", () => {
        this.setLocalFlag({ key: "map_open", status: true });
      });
    });
  },
  created() {
    this.updateUserCenter();
    this.updateUserCenterTimeout = setInterval(this.updateUserCenter, 3000);
    this.windowWidth = window.innerWidth;
  },
  beforeDestroy() {
    clearInterval(this.updateUserCenterTimeout);
    this.updateUserCenterTimeout = null;
  },
  methods: {
    ...mapActions("User", ["flagSyncTimer", "setIntroHide"]),
    ...mapActions("Game", [""]),

    setCenter(center, zoom = 16) {
      center = [center[0], center[1]];
      // this.map_config.center = center;
      this.$refs.map.mapObject.flyTo(center, zoom);
    },
    play: function() {
      this.$refs.video.play();
      this.playBtn = false;

      this.$refs.video.addEventListener(
        "ended",
        () => {
          // console.log("video play finished");

          if (this.intro_show_flag["stage_map"]) {
            let intro = {
              content:
                '<p class="text-primary">遊戲勝利條件：完成關卡裡的任務以取得好感值，好感值最高的玩家最終將贏得小桃芳心！</p><p>進入地圖後可看到六個角色頭像，每一個角色頭像代表一個關卡，由隊長選擇接下來要攻略的關卡</p><p>注意！需完成所選關卡後才能再進行其他關卡</p><p>建議選擇<span class="text-primary">離自己最近</span>或是<span class="text-primary">想要先到達的地區</span></p><p>如果卡關時可使用提示，但會減少得到的好感值</p>'
            };
            this.setInfoModal(intro);
            this.setIntroHide({ flag: "stage_map" });
          }
        },
        false
      );
    },
    team_goto(location) {
      getDeviceMotionPermission();
      this.setOnline();

      let stage_lock = this.flag("stage_lock");

      if (stage_lock) {
        let lock_key = `stage_${stage_lock}_clear`;
        let stage_is_done = this.flag(lock_key);
        if (stage_is_done) this.unlockStage();
      }

      //特別判定是否為車站關卡，並且是否已解鎖
      //判定車站關卡的程式邏輯很分散非常糟糕，但目前只能先保留這個結構並且往下寫
      if (location.stage == 7) {
        let isAllOtherStageClear =
          this.flag("stage_1_clear") &&
          this.flag("stage_2_clear") &&
          this.flag("stage_3_clear") &&
          this.flag("stage_4_clear") &&
          this.flag("stage_5_clear") &&
          this.flag("stage_6_clear");
        if (!isAllOtherStageClear) {
          this.setModal("先完成其他關卡 才能進行這關");
          return;
        }
      }

      // 如果已經解鎖則可自由進入
      if ((location.unlockFlag && this.flag(location.unlockFlag)) || /food/.test(location.link)) {
        if (location.unlockFlag && this.isLeader) {
          this.setFlag({ key: location.unlockFlag, status: true });
        }
        //如果是美食任務就直接進入
        this.$router.replace(location.link);
      } else {
        if (stage_lock && (stage_lock !== location.stage_lock)) {
          const lock_stage_data = locations.find(d=>d.stage_lock==stage_lock)
          this.setModal(`要先結束${lock_stage_data?.name ?? '目前關卡'}才能玩別關`);
          return;
        } else {
          if(!this.isLeader){
            this.setModal("只有隊長可以開啟關卡");
            return;
          }
          if (!this.allMemberOnline) {
            this.setModal("有人離線中 請確定大家都在線上");
            return;
          }
          if (location.unlockFlag) {
            this.setFlag({ key: location.unlockFlag, status: true });
          }


          if (location.stage_lock) {
            this.lockStage(location.stage_lock);
          }

          const data = {
            method: "EnterStage",
            token: this.token,
            location
          };
          sendMessage(data);
        }
      }
    },
    member_goto(location) {
      getDeviceMotionPermission();
      if (location.unlockFlag && !this.flag(location.unlockFlag) && !/food/.test(location.link)) {
        this.setModal("請等待隊長選擇闖關區域");
        return;
      }
      this.$router.replace(location.link);
    },
    useIcon(data, options = {}) {
      if (data?.icon?.iconUrl) {
        // data.icon.className = "stage-icon-" + data.id;
        options = { ...data.icon, ...options };
        // 根據當前關卡進度來更換狀態圖片
        // 替換規則請參照 https://docs.google.com/spreadsheets/d/1tf7MjNT_0w70sOHmdghYoBcyvuZaki66XpCWA6hwFCU/edit#gid=0

        let newIconUrl = options.iconUrl;
        let stateCode = 0;

        //狀態圖片後者覆蓋前者
        if (data.stage == 7) {
          stateCode = 1;
        }
        if (
          this.flag("stage_1_clear") &&
          this.flag("stage_2_clear") &&
          this.flag("stage_3_clear") &&
          this.flag("stage_4_clear") &&
          this.flag("stage_5_clear") &&
          this.flag("stage_6_clear")
        ) {
          stateCode = 0;
        }
        if (this.flag("stage_" + data.stage + "_default") && (data.stage == 5 || data.stage == 6 || data.stage == 7)) {
          //這幾關因為不用先解題，因此只要選中就會改圖片
          stateCode = 2;
        }
        if (this.flag("stage_" + data.stage + "_phase1Clear")) {
          stateCode = 2;
        }
        if (this.flag("stage_" + data.stage + "_check1stPlace")) {
          stateCode = 3;
        }
        if (this.flag("stage_" + data.stage + "_clear")) {
          stateCode = 4;
        }
        if (stateCode > 0 && /cha\d\.png/.test(options.iconUrl)) {
          newIconUrl = options.iconUrl.replace(/\.png/, "-" + stateCode + ".png");
        }

        options.iconUrl = newIconUrl;
        return icon(options);
      }
      return icon({
        iconUrl: "/img/icon-pin.svg",
        iconSize: [69, 102],
        iconAnchor: [34, 102]
      });
    },
    filterUnlockLocation(locations) {
      return locations.filter(d => d.unlock);
    },
    switchPicture() {
      this.showUserPicture = !this.showUserPicture;
    },
    updateUserCenter() {
      if (navigator.geolocation) {
        this.userCenter = [this.coords.latitude, this.coords.longitude];
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    setIndex(index) {
      // console.log(index)
      let locationLength = this.locations_dis.length;
      if (index >= locationLength) {
        index = locationLength - 1;
      } else if (index < 0) {
        index = 0;
      }
      this.localIndex = index;
      this.collapse = true;
      let local = this.locations_dis[index].local;
      this.map_config.center = local;
    },
    async returnPosition() {
      // this.$refs.map.mapObject.setView(this.map_config.center, this.zoom);
      console.log("this.userCenter=", this.userCenter);
      if (this.userCenter[0] == 0) {
        this.map_config.center = [25.041679, 121.514963];
      } else {
        this.map_config.center = this.userCenter;
      }
    },
    areaBgColor(status) {
      if (status == "default") {
        return 1;
      } else {
        return 0;
      }
    },
    boundBorderColor(status) {
      if (status == "clear") {
        return "#f68800";
      } else {
        return "#FD6180";
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.map-container {
  width: 100vw;
  height: 100vh;
}

.pic-switch {
  width: 50px;
  height: 26px;
  background: linear-gradient(to bottom, #173052, #e5e2eb);
  border-radius: 20px;
  z-index: 999;
  right: 20px;
  top: 20px;

  &.active {
    .switch-circle {
      left: 27px;
    }
  }
}

.switch-circle {
  position: absolute;
  left: 2px;
  top: 3px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.cha-block {
  height: 180px;
  margin: 10px 0;
}

.cha1 {
  background: url(../assets/images/cha1.svg) no-repeat right bottom;
}

.team-cha-name {
  width: 80px;
}

.team-rank {
  background: #1e4165;
  color: #fff;
  padding: 6px 23px;
  margin: 0px -10px;
  top: 40%;
  width: calc(100% + 20px);
}

.btn-sm {
  font-size: 12px;
  padding: 8px 30px;
}

.btn-backToFinalPanel {
  position: absolute;
  z-index: 1000;
  top: 5vw;
  left: 30vw;
  width: 40vw;
}
.return-position {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  @include default-shadow;
  z-index: 9998;
  right: 20px;
  bottom: 210px;
}

.location-list {
  bottom: 20px;
  /*right: 0;*/
  left: 10px;
  z-index: 1000;
  padding: 0 0px;
  transition: left 0.4s, bottom 0.5s 0.4s;

  &.hide-menu {
    bottom: -300px;
  }

  .go-location {
    top: 10px;
    right: 10px;
    font-size: 12px;
  }

  .location-card {
    flex-shrink: 0;
    margin: 0 10px;
    padding: 30px 50px 30px 20px;
    background: #fff;
    height: 100%;
    border-radius: 23px;
    width: calc(100vw - 70px);
    @include default-shadow;
  }
}

.leaflet-marker-pane.leaflet-pane img:first-child {
  width: 100px;
  height: 100px;
}

.leaflet-container .leaflet-control-attribution {
  background: none;
}
</style>
